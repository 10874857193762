@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

canvas {
  height: 100vh !important;
}

@media (max-width: 1418px) {
  
  .social-desktop {
    display: none !important;
  }

  .social-tablet {
    display: flex !important;
  }

  .profile-container {
    margin: auto !important;
  }

  .contact-info {
    margin: 0 !important ;
    /* width: 50% !important; */
  }

  .profile-picture-container {
    /* margin: auto; */
    width: 30% !important;
    /* margin-bottom: 2rem;  */
  }

  
}

@media (max-width: 1000px) {
  .profile-container {
    flex-direction: column;
  }

  .profile-picture-container {
    margin: auto;
  }
   .profile_picture {
    margin-bottom: 2rem !important;
   }

   .profile {
     margin-top: 8vh !important;
   }

   .profile-picture-container {
    /* margin: auto; */
    width: 40% !important;
    /* margin-bottom: 2rem;  */
  }

  .contact-info {
    margin: 0 1.5rem !important;
  }

}

@media (max-width: 540px) {
  .contact-info h1{
    font-size: 1.6rem !important;
  }

  .contact-info .tagline{
    font-size: 12px;
  }

}

@media (max-height: 730px) {
  .profile {
    margin-top: 4vh !important;
  }
}

@media (max-width: 320px) {
  .contact-info {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    margin: 0 !important;
} 

}

.profile-picture-container {
  width: 15vw;
}

.contact-info {
  /* width: 60%; */
  margin: 0 3rem; 
  display: flex;
}

.profile-container {
  display: flex; 
  margin: 0 8vw; 
  justify-content: center;
}

.social-desktop {
  display: flex;
  width: 5%;
}

.social-tablet {
  display: none;
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media {
  .container{
    max-width: 1270px;
  }
}

html, body {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
}




.profile_header{
  height: 100vh;
  overflow-x: hidden;
  text-align: center;
  background-color: #20232A;
  overflow-y: hidden;
  color: white;
}

.info-card{
  padding: 35px;
  margin-right: 12px;
  margin-left:12px;

}

.logo {
  width: 100px;
  padding-bottom: 25px;
  height: auto;
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(1);
  /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  /* Microsoft Edge and Firefox 35+ */
}

.info-hover:hover .logo{
  -webkit-filter: grayscale(0);
  filter: none;
}

.logo-container {
  text-align: center;
}

.info {
  padding-top: 20px;
  background-color: #F5F5F5;
  padding-bottom: 100px;
}

.profile {
  position: absolute;
  left: 15%;
  right: 15%;
  padding: 6% 0;
  margin-top: 20vh;

  /*border-style: dotted;*/
  background-color: #282C34dd;
}

.info-title {
  text-align: center;
  padding-bottom: 35px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 24px;

}

.profile_text {
  display: inline-block;
  margin-right: 50px;
  margin-left: 50px;
  margin-top: 40px;
  -webkit-transform: translateY(2vw);
          transform: translateY(2vw)
}

.course_list {
  padding-left: 0px;
  color: #777;
  margin-left: 20px;
}


.profile_picture {
  border-radius: 50%;
  display: inline-block;
  width: 100%;

}

.right {
  float: right;
}

.portfolio-button-container {
  background-color: #777777;
  height: 250px;
}

.portfolio-button {
  -webkit-transform: translateY(14vh);
          transform: translateY(14vh);
}

.skill-title{
  padding-bottom: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 24px;
}

.skill-badge {
  margin-right: 5px;
  margin-bottom: 5px;
}

.skill-container {
  margin-bottom: 30px;
  text-align: center;

}

.social-logo {
  margin-right: 5px;
  margin-left: 5px;
  color: #FFF;
}

.social-link-github:hover{
  border-color: #e570e7;
}


.social-link-linkedin:hover{
  border-color:  #9470e7;
}

.social-link-instagram:hover{
  border-color:  #79f1fc;
}


.social-link {
  font-size: 25px;
  padding: 15px 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid  #e570e700;
  border-radius: 80px;
  font-size: 23px;

}


.footer{
  padding-bottom: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  background-color: #F9F9F9;
}

.portfolio-words{
  font-size: 70px;
}

.project_row {
  border: none;
  padding: 16px 32px;
  padding-top: 40px;
  margin: 4px 4px 4px 0px;
  opacity: 0.5;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.project_row img{
  width: 100px;
  height: auto;
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(1);
  /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
}

.project_row:hover {
  opacity: 1;
}


.project_row:hover img{
  -webkit-filter: grayscale(0);
  filter: none;
}

.portfolio-container{
  background-color: #F9F9F9;
  text-align: center;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.project-container{
  overflow-y: auto;
  overflow-x: hidden;
  height: 60vh;
}

.portfolio-tabs{
  min-width: 400px;
  text-align: center;
  display: inline-block;
  align-items: center;
}

.photography-gallery-container{
  margin-top: 30px;
  display: inline-block;
  max-width: 85vw;
  overflow-y: scroll;
  max-height: 70vh;
}

.footer-link{
  color: #000000;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, rgba(255,255,255,0)), color-stop(50%, yellow));
  background-image: -webkit-linear-gradient(left, rgba(255,255,255,0) 50%, yellow 50%);
  background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, yellow 50%);
  background-position: -0% 0;
  background-size: 200% auto;
  line-height: 1.15em;
  text-decoration: none;
  -webkit-transition: background-position 0.5s ease-out;
  transition: background-position 0.5s ease-out;
  padding-left: 10px;
  padding-right: 10px;
}

.footer-link:hover{
  background-position: -99.99% 0;
  text-decoration: none;
  color: inherit;
}

.e-mail{
  font-size: 14px;
  letter-spacing: 3px;
  font-weight: lighter;
}

  

/* ---------- */


button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;

}
button.learn-more {
  width: 12rem;
  height: auto;
}
button.learn-more .circle {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #20232A;
  border-radius: 1.625rem;
}
button.learn-more .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
button.learn-more .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.8rem;
  height: 1.8rem;
  background: none;
  color: #fff;
}

.button-text {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 3.8rem;
  color: #fff;
  font-weight: 700; 
  line-height: 1.6;
  text-align: left;
  text-transform: uppercase;
}
button:hover .circle {
  width: 105%;
  background: -webkit-linear-gradient(315deg, #e570e7 0%,#79f1fc 100%);
  background: linear-gradient(135deg, #e570e7 0%,#79f1fc 100%);
}
button:hover .circle .icon {
  -webkit-transform: translate(0.6rem, 0);
          transform: translate(0.6rem, 0);
}
button:hover .button-text {
  color: #fff;

}


.downArrow{
    position: fixed;
    left: 39vw;
    bottom: -15vh;
    /*border-style: dotted;*/
}

.bounce {
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}
